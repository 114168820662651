/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.cr-user-info {
  background-color: transparent;
  padding: 7px 12px;
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
@media screen and (min-width: 768px) {
  .cr-user-info {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 71px;
  }
}
.cr-user-info .ant-dropdown-link {
  color: inherit;
}
.cr-user-info .ant-dropdown-link .anticon {
  font-size: 12px;
}
.cr-user-info.light .ant-dropdown-link {
  color: inherit;
}
.cr-user-info.light .cr-user-name {
  color: inherit;
}
.cr-user-info-inner {
  display: flex;
  align-items: center;
}
.cr-user-info-avatar {
  font-size: 24px;
  background-color: #fa8c16;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cr-user-info-content {
  width: calc(100% - 62px);
  margin-left: 16px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[dir=rtl] .cr-user-info-content {
  margin-left: 0;
  margin-right: 16px;
}
.cr-user-name-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cr-user-name {
  margin-bottom: 0;
  font-size: 17px;
  font-weight: 500;
  color: inherit;
}
.cr-user-name.light {
  color: inherit;
}
.cr-user-arrow {
  margin-left: 12px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
[dir=rtl] .cr-user-arrow {
  margin-left: 0;
  margin-right: 12px;
}
.cr-user-arrow svg {
  display: block;
}
.cr-user-designation {
  margin-top: -2px;
  color: inherit;
}
.ant-layout-sider-dark .cr-user-designation {
  color: inherit;
}
.dropdown-list .ant-list-item {
  padding: 5px 12px;
  cursor: pointer;
}
.dropdown-list .ant-list-item:hover {
  background-color: #f7f7f7;
}
.cr-user-info-hasColor .ant-dropdown-link {
  color: inherit;
}
.cr-user-info-hasColor.light .ant-dropdown-link {
  color: inherit;
}
.cr-user-info-hasColor.light .cr-user-name {
  color: inherit;
}
.cr-user-info-hasColor .cr-user-designation {
  color: inherit;
}
.ant-layout-sider-dark .cr-user-info-hasColor .cr-user-designation {
  color: inherit;
}
.cr-user-info-hasColor .cr-user-name {
  color: inherit;
}
.cr-user-info-hasColor .cr-user-name.light {
  color: inherit;
}
