/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-layout-mini-sidebar {
  min-height: 100vh;
  position: relative;
  background-color: transparent;
  padding-top: 56px;
}
@media screen and (min-width: 768px) {
  .app-layout-mini-sidebar {
    padding-top: 71px;
  }
}
.app-layout-mini-sidebar.appMainFixedFooter {
  padding-bottom: 46px;
}
.app-layout-mini-sidebar.appMainFixedFooter .app-main-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
}
.mini-sidebar-toggle.ant-layout-sider {
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 0 !important;
  width: 17.5rem !important;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
  position: fixed;
  left: 0;
  top: 0;
}
[dir=rtl] .mini-sidebar-toggle.ant-layout-sider {
  left: auto;
  right: 0;
}
@media screen and (min-width: 992px) {
  .mini-sidebar-toggle.ant-layout-sider {
    width: 17.5rem !important;
  }
}
.mini-sidebar-toggle .ant-layout-sider-trigger {
  display: none;
}
.mini-sidebar-toggle.ant-layout-sider-has-trigger {
  padding-bottom: 0;
}
.mini-sidebar-toggle.ant-layout-sider-collapsed {
  width: 4rem !important;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
.mini-sidebar-toggle.ant-layout-sider-collapsed + .app-layout-mini-sidebar-main {
  width: calc(100% - 4rem) !important;
  margin-left: 4rem;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
[dir=rtl] .mini-sidebar-toggle.ant-layout-sider-collapsed + .app-layout-mini-sidebar-main {
  margin-left: 0;
  margin-right: 4rem;
}
.mini-sidebar-toggle.ant-layout-sider-collapsed + .app-layout-mini-sidebar-main .app-header-mini-sidebar {
  width: calc(100% - 4rem) !important;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
.mini-sidebar-toggle .cr-user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.app-header-mini-sidebar {
  padding-left: 20px;
  padding-right: 20px;
  color: #111827;
  background-color: #fff;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  height: 56px;
  width: 100% !important;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
  display: flex;
  align-items: center;
}
[dir=rtl] .app-header-mini-sidebar {
  right: auto;
  left: 0;
}
@media screen and (min-width: 768px) {
  .app-header-mini-sidebar {
    padding-left: 30px;
    padding-right: 30px;
    height: 71px;
  }
}
@media screen and (min-width: 1200px) {
  .app-header-mini-sidebar {
    width: calc(100% - 17.5rem) !important;
  }
}
.app-header-mini-sidebar .trigger {
  font-size: 35px;
  margin-right: 20px;
  padding: 5.5px;
  cursor: pointer;
}
[dir=rtl] .app-header-mini-sidebar .trigger {
  margin-right: 0;
  margin-left: 20px;
}
.header-search-mini-sidebar {
  position: relative;
  max-width: 140px;
  min-height: 36px;
  margin-left: auto;
  margin-right: 10px;
}
[dir=rtl] .header-search-mini-sidebar {
  margin-left: 10px;
  margin-right: auto;
}
@media screen and (min-width: 576px) {
  .header-search-mini-sidebar {
    margin-right: 20px;
    max-width: 165px;
  }
  [dir=rtl] .header-search-mini-sidebar {
    margin-right: auto;
    margin-left: 20px;
  }
}
.header-search-mini-sidebar .ant-input-wrapper {
  top: 50%;
  right: 0;
  z-index: 1;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
[dir=rtl] .header-search-mini-sidebar .ant-input-wrapper {
  right: auto;
  left: 0;
}
.header-search-mini-sidebar .ant-input {
  padding: 8px 14px;
  height: 36px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 104px;
}
.header-search-mini-sidebar .ant-input:focus {
  width: 135px;
}
@media screen and (min-width: 576px) {
  .header-search-mini-sidebar .ant-input {
    width: 129px;
  }
  .header-search-mini-sidebar .ant-input:focus {
    width: 165px;
  }
}
.header-search-mini-sidebar .ant-input-search-button {
  height: 36px;
  width: 36px;
  box-shadow: none;
}
.header-search-mini-sidebar .ant-input-search-button .anticon svg {
  display: block;
}
.header-search-mini-sidebar.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 4px 0 0 4px;
}
.app-header-mini-sidebar-sectionDesktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .app-header-mini-sidebar-sectionDesktop {
    display: flex;
    align-items: center;
  }
}
.app-header-mini-sidebar-section-mobile {
  display: block;
}
@media screen and (min-width: 768px) {
  .app-header-mini-sidebar-section-mobile {
    display: none;
  }
}
.app-header-mini-sidebar-section-mobile .ant-dropdown-link {
  font-size: 24px;
  color: #111827;
}
.app-header-mini-sidebar-section-mobile .ant-dropdown-link svg {
  display: block;
}
.app-layout-mini-sidebar-main {
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100% !important;
  margin-left: 0;
  position: relative;
}
[dir=rtl] .app-layout-mini-sidebar-main {
  margin-right: 0;
}
@media screen and (min-width: 1200px) {
  .app-layout-mini-sidebar-main {
    width: calc(100% - 17.5rem) !important;
    margin-left: 17.5rem;
  }
  [dir=rtl] .app-layout-mini-sidebar-main {
    margin-left: 0;
    margin-right: 17.5rem;
  }
}
.main-mini-sidebar-scrollbar {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(100vh - 56px);
}
.appMainFixedFooter .main-mini-sidebar-scrollbar {
  max-height: calc(100vh - 104px);
}
@media screen and (min-width: 576px) {
  .main-mini-sidebar-scrollbar {
    max-height: calc(100vh - 71px);
  }
  .appMainFixedFooter .main-mini-sidebar-scrollbar {
    max-height: calc(100vh - 118px);
  }
}
.app-mini-sidebar-scrollbar {
  height: calc(100vh - 56px);
}
.appMainFixedFooter .app-mini-sidebar-scrollbar {
  height: calc(100vh - 102px);
}
@media screen and (min-width: 768px) {
  .app-mini-sidebar-scrollbar {
    height: calc(100vh - 71px);
  }
  .appMainFixedFooter .app-mini-sidebar-scrollbar {
    height: calc(100vh - 116px);
  }
}
.mini-sidebar-toggle.ant-layout-sider-collapsed .cr-user-info {
  padding-left: 12px;
  padding-right: 12px;
}
.mini-sidebar-toggle.ant-layout-sider-collapsed .cr-user-info-content {
  opacity: 0;
  visibility: hidden;
  width: 0;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
}
[dir=rtl] .mini-sidebar-toggle.ant-layout-sider-collapsed .cr-user-info-content {
  margin-left: 0;
  margin-right: 0;
}
.mini-sidebar-toggle.ant-layout-sider-collapsed .ant-menu-item-group-title {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
}
.mini-sidebar-toggle-img-background {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.mini-sidebar-toggle-img-background:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  z-index: 1;
  opacity: 0.5;
}
.mini-sidebar-toggle-img-background > div {
  position: relative;
  z-index: 3;
}
.mini-sidebar-toggle-img-background .ant-menu.ant-menu-dark,
.mini-sidebar-toggle-img-background .ant-menu-dark .ant-menu-sub,
.mini-sidebar-toggle-img-background .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent;
  color: inherit;
}
.mini-sidebar-toggle-img-background .mini-sidebar-toggle-menu.ant-menu-dark .ant-menu-item-group-title,
.mini-sidebar-toggle-img-background .mini-sidebar-toggle-menu .ant-menu-item a {
  color: inherit;
}
