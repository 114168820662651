/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.message-list-item {
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 0 none !important;
  white-space: inherit;
  line-height: 1.4;
}
.message-list-item .ant-list-item-meta {
  align-items: center;
}
.message-avatar {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}
[dir=rtl] .message-avatar {
  margin-right: 0;
  margin-left: 12px;
}
@media screen and (min-width: 768px) {
  .message-avatar {
    margin-right: 16px;
  }
  [dir=rtl] .message-avatar {
    margin-right: 0;
    margin-left: 16px;
  }
}
.message-list-item-content {
  font-size: 15px;
  color: #6b7280;
  flex: 1;
}
.message-list-item-content h3 {
  margin-bottom: 2px;
  font-weight: 500;
  font-size: 15px;
}
.message-list-item-content p {
  margin-bottom: 0;
}
.message-list-item-content span {
  color: #111827;
  font-weight: 500;
  margin-right: 8px;
}
[dir=rtl] .message-list-item-content span {
  margin-right: 0;
  margin-left: 8px;
}
