/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.langBtn {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 17px;
  border-radius: 0;
  padding: 2px 12px 18px 12px;
  color: #111827;
  text-transform: capitalize;
}
.langBtn:hover,
.langBtn:focus {
  color: #111827;
  background-color: transparent;
}
@media screen and (min-width: 768px) {
  .langBtn {
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: #f4f7fe;
    color: #6b7280;
    padding: 9px;
    border-radius: 50%;
    border: solid 1px transparent;
    margin-left: 7.4px;
    margin-right: 7.4px;
    line-height: 1;
  }
  .langBtn:hover,
  .langBtn:focus {
    color: #111827;
    background-color: #f6f9fe;
    border-color: #c5d5fa;
  }
}
.lang-text {
  font-size: 17px;
  font-weight: 400;
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .lang-text {
    display: none;
  }
}
.lang-icon {
  position: relative;
  display: none;
}
@media screen and (min-width: 768px) {
  .lang-icon {
    display: block;
  }
}
.langItem {
  width: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.langItem h4 {
  margin-left: 16px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 15px;
}
[dir=rtl] .langItem h4 {
  margin-left: 0;
  margin-right: 16px;
}
@media screen and (min-width: 1200px) {
  .langItem h4 {
    font-size: 17px;
  }
}
