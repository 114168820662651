/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
}
@media screen and (min-width: 1600px) {
  .error-container {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
.error-img {
  width: 100%;
  margin-bottom: 16px;
  max-width: 200px;
}
@media screen and (min-width: 576px) {
  .error-img {
    max-width: 300px;
  }
}
@media screen and (min-width: 1600px) {
  .error-img {
    margin-bottom: 32px;
    max-width: 706px;
  }
}
.error-img svg {
  width: 100%;
  height: 400px;
  fill: #0A8FDC;
}
.error-img-lg {
  width: 100%;
  margin-bottom: 16px;
  max-width: 300px;
}
@media screen and (min-width: 576px) {
  .error-img-lg {
    max-width: 400px;
  }
}
@media screen and (min-width: 1600px) {
  .error-img-lg {
    margin-bottom: 32px;
    max-width: 500px;
  }
}
.error-img-lg svg {
  width: 100%;
  height: 400px;
  fill: #0A8FDC;
}
.error-content {
  margin-bottom: 16px;
}
@media screen and (min-width: 1600px) {
  .error-content {
    margin-bottom: 20px;
  }
}
.error-content h3 {
  margin-bottom: 12px;
  font-size: 20px;
}
@media screen and (min-width: 992px) {
  .error-content h3 {
    font-size: 22px;
  }
}
@media screen and (min-width: 1600px) {
  .error-content h3 {
    margin-bottom: 40px;
    font-size: 24px;
  }
}
.error-content-lg {
  margin-bottom: 32px;
}
@media screen and (min-width: 1600px) {
  .error-content-lg {
    margin-bottom: 64px;
  }
}
.error-para {
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: 500;
  color: #757575;
}
@media screen and (min-width: 1600px) {
  .error-para {
    margin-bottom: 40px;
  }
}
.error-btn {
  font-weight: 500;
  font-size: 17px;
  text-transform: capitalize;
}
.error-form-coming {
  max-width: 384px;
  margin: 0 auto 20px;
}
.error-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;
  text-align: left;
}
[dir=rtl] .error-form {
  text-align: right;
}
.error-form .form-field {
  margin-bottom: 20px;
}
