/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.app-layout-hor {
  min-height: 100vh;
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column !important;
}
.app-layout-hor .customizer-option {
  position: fixed;
  top: 205px;
}
@media screen and (min-width: 1600px) {
  .app-layout-hor .customizer-option {
    top: 225px;
  }
}
.app-layout-hor.appMainFixedFooter {
  padding-bottom: 46px;
}
.app-layout-hor.appMainFixedFooter .app-main-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9;
  width: 100%;
}
.app-main-hor-sidebar.ant-layout-sider {
  flex: 0 0 auto !important;
  max-width: none !important;
  min-width: 0 !important;
  width: 17.5rem !important;
  -webkit-transition: all 0.1s linear;
  -moz-transition: all 0.1s linear;
  -ms-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  transition: all 0.1s linear;
  background-color: #fff;
  color: #111827;
  position: fixed;
  left: 0;
  top: 0;
}
[dir=rtl] .app-main-hor-sidebar.ant-layout-sider {
  left: auto;
  right: 0;
}
@media screen and (min-width: 992px) {
  .app-main-hor-sidebar.ant-layout-sider {
    width: 17.5rem !important;
  }
}
.app-main-hor-sidebar .ant-layout-sider-trigger {
  display: none;
}
.app-main-hor-sidebar.ant-layout-sider-has-trigger {
  padding-bottom: 0;
}
.app-main-hor-sidebar.ant-layout-sider-collapsed {
  width: 0 !important;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
@media screen and (min-width: 992px) {
  .app-main-hor-sidebar.ant-layout-sider-collapsed {
    width: 4rem !important;
  }
}
.app-main-hor-sidebar.ant-layout-sider-collapsed + .app-layout-main {
  width: 100% !important;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}
@media screen and (min-width: 992px) {
  .app-main-hor-sidebar.ant-layout-sider-collapsed + .app-layout-main {
    width: calc(100% - 4rem) !important;
  }
}
.app-main-hor-sidebar.ant-layout-sider-collapsed .cr-user-info {
  padding-left: 12px;
  padding-right: 12px;
}
.app-main-hor-sidebar.ant-layout-sider-collapsed .cr-user-info-content {
  opacity: 0;
  visibility: hidden;
  width: 0;
  padding-left: 0;
  padding-right: 0;
}
.app-main-hor-sidebar.ant-layout-sider-collapsed .ant-menu-item-group-title {
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 0;
  padding: 0;
}
.app-header-hor {
  color: #111827;
  -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 100% !important;
  height: auto;
  line-height: 1;
  padding: 0;
  background-color: #fff;
}
.app-header-hor .trigger {
  font-size: 24px;
  margin-right: 20px;
  padding: 5.5px;
}
@media screen and (min-width: 992px) {
  .app-header-hor .trigger {
    display: none;
  }
}
[dir=rtl] .app-header-hor .trigger {
  margin-right: 0;
  margin-left: 20px;
}
.header-search-hor {
  position: relative;
  max-width: 140px;
  min-height: 36px;
  margin-left: auto;
  margin-right: 10px;
}
[dir=rtl] .header-search-hor {
  margin-left: 10px;
  margin-right: auto;
}
@media screen and (min-width: 576px) {
  .header-search-hor {
    margin-right: 20px;
    max-width: 165px;
  }
  [dir=rtl] .header-search-hor {
    margin-right: auto;
    margin-left: 20px;
  }
}
.header-search-hor .ant-input-wrapper {
  top: 50%;
  right: 0;
  z-index: 1;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
[dir=rtl] .header-search-hor .ant-input-wrapper {
  right: auto;
  left: 0;
}
.header-search-hor .ant-input {
  padding: 8px 14px;
  height: 36px;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 104px;
}
.header-search-hor .ant-input:focus {
  width: 135px;
}
@media screen and (min-width: 576px) {
  .header-search-hor .ant-input {
    width: 129px;
  }
  .header-search-hor .ant-input:focus {
    width: 165px;
  }
}
.header-search-hor .ant-input-search-button {
  height: 36px;
  width: 36px;
  box-shadow: none;
}
.header-search-hor .ant-input-search-button .anticon svg {
  display: block;
}
.header-search-hor.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 4px 0 0 4px;
}
.app-header-hor-sectionDesktop {
  display: none;
}
@media screen and (min-width: 768px) {
  .app-header-hor-sectionDesktop {
    display: flex;
    align-items: center;
  }
}
.app-header-hor-section-mobile {
  display: block;
}
@media screen and (min-width: 768px) {
  .app-header-hor-section-mobile {
    display: none;
  }
}
.app-header-hor-section-mobile .ant-dropdown-link {
  font-size: 24px;
  color: #111827;
}
.app-header-hor-section-mobile .ant-dropdown-link svg {
  display: block;
}
.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media screen and (min-width: 1600px) {
  .container {
    max-width: 1440px;
  }
}
@media screen and (min-width: 1920px) {
  .container {
    max-width: 1720px;
  }
}
.header-alert {
  text-align: center;
  border: 0 none;
  border-radius: 0;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  padding: 10.6px 20px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-alert .ant-alert-content {
  flex: inherit;
}
[dir=rtl] .header-alert .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: 8px;
}
.header-hor-main {
  position: relative;
  background-color: #fff;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  min-height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .header-hor-main {
    min-height: 71px;
  }
}
.header-hor-main .cr-user-info {
  padding: 8px;
  background-color: transparent;
  min-height: auto;
}
@media screen and (min-width: 576px) {
  .header-hor-main .cr-user-info {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media screen and (min-width: 768px) {
  .header-hor-main .cr-user-info {
    padding-left: 20px;
  }
  [dir=rtl] .header-hor-main .cr-user-info {
    padding-left: 0;
    padding-right: 20px;
  }
}
.header-hor-main .cr-user-info-content {
  display: none;
  padding-right: 0;
}
@media screen and (min-width: 992px) {
  .header-hor-main .cr-user-info-content {
    display: flex;
    flex-direction: column;
  }
}
.header-hor-main .cr-user-name {
  font-size: 15px;
  color: #111827;
  line-height: 1.5;
}
.header-hor-main .cr-user-designation {
  margin-top: 2px;
  font-size: 13px;
}
.header-hor-main .cr-user-arrow {
  display: none;
}
.header-hor-main-flex {
  display: flex;
  align-items: center;
}
.app-layout-hor-main {
  width: 100% !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.app-layout-hor-main .main-content-view {
  padding-left: 0;
  padding-right: 0;
}
.app-sidebar-hor-scrollbar {
  height: calc(100vh - 56px) !important;
}
@media screen and (min-width: 1600px) {
  .app-sidebar-hor-scrollbar {
    height: calc(100vh - 71px) !important;
  }
}
.header-nav-hor {
  display: none;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
@media screen and (min-width: 992px) {
  .header-nav-hor {
    display: block;
  }
}
.app-main-hor-menu {
  background-color: inherit;
  color: inherit;
  border-bottom: 0 none;
}
.app-main-hor-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.app-main-hor-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  padding: 0 10px;
  border-radius: 4px;
}
.app-main-hor-menu .ant-menu-submenu .ant-menu-submenu-title {
  padding: 12px 10px;
}
.app-main-hor-menu.ant-menu-horizontal > .ant-menu-item::after,
.app-main-hor-menu.ant-menu-horizontal > .ant-menu-submenu::after {
  display: none;
}
.app-main-hor-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.app-main-hor-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.app-main-hor-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.app-main-hor-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.app-main-hor-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.app-main-hor-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.app-main-hor-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.app-main-hor-menu.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: inherit;
  border-bottom: 0 none;
}
.app-main-hor-menu .ant-menu-submenu-title:hover,
.app-main-hor-menu .ant-menu-submenu-title:focus {
  color: inherit;
}
.app-main-hor-menu.ant-menu-horizontal > .ant-menu-item,
.app-main-hor-menu.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 0 none;
}
.hor-sidebar-img-background {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
}
.hor-sidebar-img-background:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: inherit;
  z-index: 1;
  opacity: 0.5;
}
.hor-sidebar-img-background > div {
  position: relative;
  z-index: 3;
}
.hor-sidebar-img-background .ant-menu.ant-menu-dark,
.hor-sidebar-img-background .ant-menu-dark .ant-menu-sub,
.hor-sidebar-img-background .ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent;
  color: inherit;
}
.hor-sidebar-img-background .app-main-sidebar-menu.ant-menu-dark .ant-menu-item-group-title,
.hor-sidebar-img-background .app-main-sidebar-menu .ant-menu-item a {
  color: inherit;
}
.app-hor-drawer .ant-drawer-content-wrapper {
  width: 17.5rem !important;
}
.app-hor-drawer .ant-drawer-body {
  padding: 0;
}
